import { LoadingIcon } from 'components/Icons';
import { BaseProps } from 'components/types';

export type LoadingIndicatorProps = {
  size?: 'small' | 'large';
} & BaseProps;

export const LoadingIndicator = ({ size }: LoadingIndicatorProps) => {
  return (
    <LoadingIcon
      className="stroke-island"
      size={size === 'small' ? '24' : '37'}
    />
  );
};

export default LoadingIndicator;
