'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';

import { pageEvent } from 'utils/segment';

export const useSegmentPageView = <
  Event extends Parameters<typeof pageEvent>[0],
>(
  name: Event,
  options: {
    enabled?: boolean;
    runOnce?: boolean;
    properties?: Parameters<typeof pageEvent<Event>>[1];
  } = {}
) => {
  const hasRunRef = useRef(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const previousPropertiesRef = useRef<any>(undefined);
  const pathname = usePathname();
  const query = useSearchParams()?.toString();
  const { properties, enabled: _enabled, runOnce } = options;

  const enabled = _enabled ?? true;

  useEffect(() => {
    const canRun = runOnce ? !hasRunRef.current : true;
    const isEqualProps = properties
      ? JSON.stringify(properties) ===
        JSON.stringify(previousPropertiesRef.current)
      : false;
    if (enabled && !isEqualProps && canRun) {
      try {
        pageEvent(name, properties);
        hasRunRef.current = true;
        previousPropertiesRef.current = properties;
      } catch (err) {
        console.error(`Error submitting segment page view: ${err}`);
      }
    }
  }, [pathname, query, name, properties, enabled, runOnce]);
};
