import { isValidPhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

import { PropertyIntakeManagementType as ManagementType } from 'utils/__generated/graphql/graphql';

const SELF_SIGNUP_MESSAGE = 'Please answer this question.';

export { ManagementType };

export const getManagementTypeLabel = (managementType: ManagementType) => {
  switch (managementType) {
    case ManagementType.SelfManaged:
      return 'I manage my property on my own';
    case ManagementType.HasPropertyManager:
      return 'I use a property manager';
    case ManagementType.NeverRented:
      return "Nobody, I've never rented it out before";
    default:
      return managementType;
  }
};

export enum PropertyType {
  VacationHome = 'My entire vacation home or condo',
  MultipleProperties = 'I have more than one property to rent',
  ProspectiveBuyer = "No property yet, but I'm looking to buy",
  PrimaryResidence = 'My primary residence',
  Timeshare = 'My timeshare',
  PrivateRoom = 'A private room in my home',
  Guesthouse = 'A guesthouse on my property',
  MobileProperty = 'My mobile property, treehouse or campsite',
}

export enum SelfManagementDuration {
  LessThan6Months = 'Less than 6 months',
  SixMonthsOrGreater = '6 months or greater',
}

export enum AnnualAvailability {
  UNDER_10 = 'Under 10% (0-4 weeks)',
  TEN_TO_50 = '10-50% (5-26 weeks)',
  FIFTY_TO_90 = '50-90% (26-47 weeks)',
  OVER_90 = '90%+ (Over 47 weeks)',
}

export enum StrAllowedStatus {
  YES = 'Yes',
  NO = 'No',
  NOT_SURE = "I'm not sure",
}

export enum FutureProcess {
  Research = 'Research',
  Shopping = 'Shopping',
  SubmittingOffers = 'Submitting Offers',
  UnderContract = 'Under Contract',
}

export enum FuturePropertyType {
  House = 'House',
  Cabin = 'Cabin',
  Townhome = 'Townhome',
  Condo = 'Condo',
  Timeshare = 'Timeshare',
  Other = 'Other',
  Unknown = 'Unknown',
}

export enum FuturePropertyTimeFrame {
  Immediately = 'Immediately',
  OneToThreeMonths = '1-3 months',
  ThreeToSixMonths = '3-6 months',
  SixPlusMonths = '6+ months',
}

export enum FuturePropertyBudget {
  LessThan100k = 'Less than $100,000',
  From101kTo250k = '$101,000 - $250,000',
  From251kTo500k = '$251,000 - $500,000',
  From501kTo999k = '$501,000 - $999,999',
  Over1m = '$1,000,000+',
  Unknown = 'Unknown',
}

export enum FuturePropertyGoal {
  RentalOnly = 'Rental income only',
  RentalPlusMinimalPersonal = 'Rental income + minimal personal use',
  PersonalPlusMinimalRental = 'Personal use + minimal rental income',
  PersonalOnly = 'Personal use only',
}

export enum FuturePropertyAgentStatus {
  Yes = 'Yes',
  AgentIntroRequested = 'Agent Intro Requested',
  No = 'No',
}

export enum FuturePropertyMortgageStatus {
  AllCash = 'All Cash',
  PreApproved = 'Pre-approved',
  NeedRecommendations = 'Haven’t applied, would like recommendations',
  NotSure = 'Not Sure',
}

// Form schemas

export const propertyTypeFormSchema = z.object({
  propertyType: z.nativeEnum(PropertyType),
});

export const managementFormSchema = z.object({
  managementType: z.nativeEnum(ManagementType),
  selfManagementDuration: z.nativeEnum(SelfManagementDuration).nullish(),
});

export const availabilityFormSchema = z.object({
  annualAvailability: z.nativeEnum(AnnualAvailability),
  strAllowedStatus: z.nativeEnum(StrAllowedStatus),
});

export const locationFormSchema = z.object({
  formattedAddress: z.string().trim().min(1, 'Address is required'),
  address1: z.string().trim().nullish(),
  address2: z.string().trim().nullish(),
  city: z.string().trim().min(1, 'City is required'),
  region: z.string().trim().min(1, 'State is required'),
  postalCode: z.string().trim().nullish(),
  country: z.string().trim().min(1, 'Country is required'),
  latitude: z.number(),
  longitude: z.number(),
});

export const bedroomsFormSchema = z.object({
  bedrooms: z.coerce
    .number()
    .nonnegative('Invalid number of bedrooms')
    .max(50, 'Invalid number of bedrooms')
    .default(0),
});

export const nameFormSchema = z.object({
  firstName: z.string().trim().min(1, 'First name is required'),
  lastName: z.string().trim().min(1, 'Last name is required'),
});

export const contactFormSchema = z.object({
  email: z.string().trim().min(1, 'Email is required').email('Invalid email'),
  phone: z
    .string()
    .trim()
    .min(1, 'Phone is required')
    .refine((value) => isValidPhoneNumber(value, 'US'), 'Invalid phone number'),
});

export const futurePropertyFormSchema = z.object({
  futureProcess: z.nativeEnum(FutureProcess),
  futurePropertyType: z.nativeEnum(FuturePropertyType),
  futurePropertyAgentStatus: z.nativeEnum(FuturePropertyAgentStatus).nullish(),
  futurePropertyBudget: z.nativeEnum(FuturePropertyBudget).nullish(),
  futurePropertyGoal: z.nativeEnum(FuturePropertyGoal).nullish(),
  futurePropertyHasSecondHome: z.boolean().nullish(),
  futurePropertyLocationInterests: z.string().nullish(),
  futurePropertyMortgageStatus: z
    .nativeEnum(FuturePropertyMortgageStatus)
    .nullish(),
  futurePropertyTimeframe: z.nativeEnum(FuturePropertyTimeFrame).nullish(),
  city: z.string().trim().nullish(),
  region: z.string().trim().nullish(),
});

export const selfSignupQualificationFormSchema = z.object({
  hasHoa: z.boolean({ invalid_type_error: SELF_SIGNUP_MESSAGE }),
  hasRequiredFeatures: z.boolean({ invalid_type_error: SELF_SIGNUP_MESSAGE }),
  isForSale: z.boolean({ invalid_type_error: SELF_SIGNUP_MESSAGE }),
  isPrimaryResidence: z.boolean({ invalid_type_error: SELF_SIGNUP_MESSAGE }),
  isPropertyOwner: z.boolean({ invalid_type_error: SELF_SIGNUP_MESSAGE }),
  isSelfSignup: z.boolean().nullish(),
});

export const confirmationPageSchema = z.object({}).strict();

export enum PropertyIntakeVariant {
  B = 'B',
  C = 'C',
}

export const propertyIntakeVariantSchema = z
  .string()
  .trim()
  .toUpperCase()
  .pipe(z.nativeEnum(PropertyIntakeVariant))
  .nullable();

const nullableObject = <Schema extends z.AnyZodObject>(schema: Schema) => {
  const entries = Object.entries(schema.shape) as [
    keyof Schema['shape'],
    z.ZodTypeAny,
  ][];
  const newShape = entries.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        value instanceof z.ZodOptional
          ? value.unwrap().nullish()
          : value.nullish(),
    }),
    {} as {
      [key in keyof Schema['shape']]: z.ZodOptional<
        z.ZodNullable<Schema['shape'][key]>
      >;
    }
  );
  return z.object(newShape);
};

export const propertyIntakeFormSchema = z
  .object({
    id: z.string().uuid().nullish(),
    propertyType: z.nativeEnum(PropertyType),
  })
  .merge(nullableObject(managementFormSchema))
  .merge(nullableObject(availabilityFormSchema))
  .merge(nullableObject(locationFormSchema))
  .merge(nullableObject(bedroomsFormSchema))
  .merge(nullableObject(futurePropertyFormSchema))
  .merge(nullableObject(nameFormSchema))
  .merge(nullableObject(contactFormSchema))
  .merge(nullableObject(selfSignupQualificationFormSchema));

export type PropertyIntakeForm = z.infer<typeof propertyIntakeFormSchema>;
