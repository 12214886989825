'use client';
import { ButtonProps, Button } from 'components/Buttons';
import { Link, LinkProps } from 'components/Link';
import { cn } from 'utils/tailwind';

export type ButtonLinkProps = Omit<LinkProps, 'type'> &
  Omit<ButtonProps, 'href'>;

export const ButtonLink = ({
  ref,
  cta,
  asChild: _asChild,
  href,
  passHref = true,
  legacyBehavior = true,
  children,
  ...props
}: ButtonLinkProps) => {
  let asChild = _asChild;

  if (asChild === undefined || asChild === null) {
    if (href) {
      asChild = false;
    } else {
      asChild = true;
    }
  }

  if (props.disabled) {
    return <Button {...props}>{children}</Button>;
  } else {
    return (
      <Link
        asChild={asChild}
        cta={cta}
        href={href}
        legacyBehavior={legacyBehavior}
        passHref={passHref}
        ref={ref}
        className={cn(
          {
            'text-white text-sm visited:text-white no-underline hover:text-white':
              props.variant === 'solid',
            'text-cta visited:text-cta text-sm no-underline':
              props.variant === 'subtle',
            'no-underline text-sm text-cta visited:text-cta': [
              'text',
              'outline',
            ].includes(props.variant as string),
          },
          props.className
        )}
      >
        <Button {...props}>{children}</Button>
      </Link>
    );
  }
};
