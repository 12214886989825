import { useMutation } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { refreshSession } from './auth';

interface UseImpersonateProps {
  onError: (err: Error, supportUsername: string) => void;
  callbackUrl?: string;
}

export function useImpersonate({
  onError,
  callbackUrl = window.location.origin + '/',
}: UseImpersonateProps) {
  const { data: session } = useSession();
  const {
    mutate: impersonate,
    mutateAsync: impersonateAsync,
    isPending,
  } = useMutation({
    mutationFn: async (supportUsername: string) => {
      await refreshSession({
        accessToken: session?.user?.accessToken,
        supportUsername,
      });

      window.location.href = callbackUrl;
    },
    onError: (error, supportUsername) => {
      console.error(`Error impersonating: ${error}`);
      onError(error, supportUsername);
    },
  });
  return { impersonate, impersonateAsync, isPending };
}
