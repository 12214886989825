export function fetchWithLogger(...params: Parameters<typeof fetch>) {
  try {
    return fetch(...params);
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { headers, ...options } = params[1] || {};
    console.error(
      `Error fetching ${params[0]} with options: ${JSON.stringify(options)}`
    );
    throw err;
  }
}
