import { Provider, Viewport } from '@radix-ui/react-toast';
import { ReactNode, createContext, useState, use, useRef } from 'react';

import { cn } from 'utils/tailwind';

import { Toast, ToastProps } from './Toast';

export interface ToastProviderProps {
  children: ReactNode;
}

export type ToastConfig = Omit<ToastProps, 'onOpenChange'> & {
  placement?: 'left' | 'right' | 'center';
};

export interface ToastContextValue {
  showToast(config: ToastConfig): number;
  dismissToast(id: number): void;
}

export const ToastContext = createContext<ToastContextValue>({
  showToast: () => 0,
  dismissToast: () => null,
});

export const useToasts = () => use(ToastContext);

type ToastInstance = ToastConfig & { id: number };

export const ToastProvider: React.FC<
  React.PropsWithChildren<ToastProviderProps>
> = ({ children }) => {
  const toastId = useRef(0);
  const [activeToasts, setActiveToasts] = useState<ToastInstance[]>([]);
  const [placement, setPlacement] = useState<ToastConfig['placement']>('right');

  const showToast = (config: ToastConfig) => {
    const instance = { ...config, id: (toastId.current += 1) };
    setActiveToasts([...activeToasts, instance]);
    setPlacement(config.placement);
    return instance.id;
  };

  const dismissToast = (id: number) => {
    setActiveToasts(activeToasts.filter((t) => t.id !== id));
  };

  return (
    <Provider>
      <ToastContext value={{ showToast, dismissToast }}>
        {children}
        <Viewport
          className={cn(
            'fixed inset-x-0 bottom-0 z-[51] m-0 flex list-none flex-col p-4',
            {
              'items-start': placement === 'left',
              'items-end': placement === 'right',
              'items-center': placement === 'center',
            }
          )}
        />
        {activeToasts.map((t) => (
          <Toast
            key={t.id}
            {...t}
            onOpenChange={(open) => !open && dismissToast(t.id)}
          />
        ))}
      </ToastContext>
    </Provider>
  );
};
