// Shared search params parser declarations
// https://nuqs.47ng.com/docs
//
// Server: see caches in server/searchParams.ts
// Client: useQueryStates(parsers)

import {
  parseAsJson,
  parseAsString,
  parseAsInteger,
  parseAsBoolean,
  createParser,
  type ParserBuilder,
} from 'nuqs/server';
import { z } from 'zod';

import { propertyIntakeVariantSchema } from 'types/RFI';
import { type BookingDetailSource } from 'utils/constants/BOOKING_DETAIL_SOURCE';
import { type ReportIncidentSource } from 'utils/constants/REPORT_INCIDENT_SOURCE';

export const globalSearchParamsSchema = z
  .object({ selectedListing: z.string().optional() })
  .strict();

//
// Web
//

/**
 * Global search params parsers
 */
export const globalSearchParamsParsers = {
  g: parseAsJson(globalSearchParamsSchema.parse).withDefault({
    selectedListing: undefined,
  }),
} as const;

/**
 * Blocking UI search params parsers
 */
export const blockingUISearchParamsParsers = {
  hideBlockingUI: parseAsBoolean.withDefault(false),
} as const;

/**
 * Login search params parsers
 */
export const loginSearchParamsParsers = {
  /** Redirect user to expected URL or home page */
  callbackUrl: parseAsString.withDefault('/'),
  /** Can be "true" or Evolve user to impersonate */
  impersonateUser: parseAsString,
  /** Enable support mode */
  support: parseAsBoolean.withDefault(false),
} as const;

/**
 * Forgot password search params parsers
 */
export const forgotPasswordSearchParamsParsers = {
  loginAttempts: parseAsInteger.withDefault(0),
} as const;

/**
 * Bookings search params parsers
 */
export const bookingsSearchParamsParsers = {
  tab: parseAsString.withDefault('calendar'),
} as const;

/**
 * Booking details search params parsers
 */
export const bookingDetailsSearchParamsParsers = {
  source: parseAsString as ParserBuilder<BookingDetailSource>,
} as const;

/**
 * Listing search params parsers
 */
export const listingSearchParamsParsers = {
  tab: parseAsString.withDefault('overview'),
} as const;

/**
 * Reports search params parsers
 */
export const reportsSearchParamsParsers = {
  tab: parseAsString.withDefault('completed-payouts'),
} as const;

/**
 * Report incident search params parsers
 */
export const reportIncidentSearchParamsParsers = {
  booking: parseAsString.withDefault(''),
  source: parseAsString as ParserBuilder<ReportIncidentSource>,
  returnUrl: parseAsString.withDefault('/'),
} as const;

/**
 * Property intake search params parsers
 */
export const propertyIntakeSearchParamsParsers = {
  variant: createParser({
    parse: (value) => {
      const parsedVariant = propertyIntakeVariantSchema.safeParse(value);
      return parsedVariant.success ? parsedVariant.data : null;
    },
    serialize: (value) => value,
    eq: (a, b) => a.trim().toLowerCase() === b.trim().toLowerCase(),
  }),
  utm_campaign: parseAsString,
  utm_content: parseAsString,
  utm_medium: parseAsString,
  utm_source: parseAsString,
  utm_term: parseAsString,
  gclid: parseAsString,
} as const;

//
// Mobile
//

/**
 * Mobile login search params parsers
 */
export const mobileLoginSearchParamsParsers = {
  returnURL: parseAsString.withDefault('/'),
  accessToken: parseAsString,
  username: parseAsString,
} as const;

/**
 * Mobile bookings search params parsers
 */
export const mobileBookingsSearchParamsParsers = {
  showHeader: parseAsBoolean.withDefault(false),
} as const;
